import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { fade, makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core';

import { TermsConditionsEn } from './TermsConditionsEn';
import { TermsConditionsEs } from './TermsConditionsEs';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 600,
      padding: 15,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
  label: {
    color: fade(theme.palette.text.primary, 0.54),
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
}));

export const RegisterTermsConditionsDialog = ({ open, onClose, setTermsDisabled, setTermsChecked }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const acceptTerms = () => {
    setTermsDisabled(false);
    setTermsChecked(true);
    onClose();
  };

  const declineTerms = () => {
    setTermsChecked(false);
    onClose();
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>{t('conditions.of.use')}</DialogTitle>
      <DialogContent>
        { ['en'].includes(i18n.language)
          ? <TermsConditionsEn />
          : <TermsConditionsEs />}
        <DialogActions>
          <Button
            className={classes.button}
            onClick={declineTerms}
            variant="outlined"
            color="primary"
          >
            {t('decline')}
          </Button>
          <Button
            className={classes.button}
            onClick={acceptTerms}
            variant="contained"
            color="primary"
          >
            {t('accept')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
